.feedback-dm {
    &__status-menu {
        min-width: 160px;
    }
    &__response {
        cursor: pointer;
        &.no-answer {
            color: $grey500;
        }
    }
    &__status-option {
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: 12px;
        height: 20px;
        padding: 0 8px 0 2px;

        &.--fixed {
            background: #ebfbf1;
            color: #2b7d31;
        }
        &.--in-review {
            background: $lightBlue2;
            color: $darkBlue2;
        }
        &.--skipped {
            background: $grey100;
            color: $grey500;
        }
    }
}
