.upload-files {
    &__body {
        flex-direction: column;
        display: flex;
        padding: 20px 35px;
        gap: 4px;
        height: 100%;
        overflow-y: auto;
        scrollbar-gutter: stable;
        &::-webkit-scrollbar {
            width: $scrollBarWidth;
        }
        &::-webkit-scrollbar-thumb {
            background: $grey400;
            border-radius: 60px;
        }
    }
    &__drop-area {
        height: 214px;
        margin-top: 23px;
        gap: 4px;
        background: $grey100;
        color: $Primary;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px dashed $grey300;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s;
        &.--disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }

        &__specifications {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 4px;
            color: $grey500;
        }

        &__info-icon {
            &:hover {
                color: $Primary;
            }
        }
    }

    &__files {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__file {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__data {
            color: $black800;
            display: flex;
            gap: 4px;
            align-items: center;
            transition: color 0.5s;
            &.--invalid {
                color: $grey400;
            }
        }

        &__name {
            font-size: 14px !important;
            max-width: 380px;
            height: 18px;
        }

        &__media-type {
            min-width: 16px;
        }

        &__delete {
            color: $grey500;
            cursor: pointer;
            margin-left: auto;
            min-width: 24px;
            transition: color 0.3;
            &:hover:not(.--disabled) {
                color: $darkRed;
            }
            &.--disabled {
                cursor: default;
                color: $grey300;
            }
            &.--progressing {
                cursor: progress;
            }
        }

        &__metadata {
            display: flex;
            align-items: center;
            gap: 6px;
            color: $grey500;
            margin-left: 20px;
            height: 18px;
        }

        &__validation-type {
            display: flex;
            gap: 7px;
            align-items: center;
            font-weight: 600 !important;
            font-size: 11px !important;
            &.--valid {
                color: #276858;
            }
            &.--corrupt,
            &.--bad-filename,
            &.--exists {
                color: $darkRed;
            }
            &.--protected {
                color: #f67b0a;
            }
        }
        &__reupload-btn {
            display: flex;
            align-items: center;
            margin-left: -6px;
            color: $Primary;
            cursor: pointer;
            &:hover {
                color: $PrimaryDarker;
            }
        }
    }

    &__model-select-popover {
        z-index: 1300;
    }
    &__model-select-menu {
        max-width: 450px !important;
        width: 450px;
        &:not(.--with-footer) {
            padding: 6px 0;
        }
    }
    &__model-select-search {
        textarea {
            color: $black800;
        }
    }

    &__model-select-add-model {
        color: $Primary;
        display: flex;
        align-items: center;
        transition: opacity 0.3s;
        cursor: pointer;
        &:hover:not(.--disabled) {
            color: $PrimaryDarker;
        }
        &.--disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }
    &__persona {
        gap: 4px;
        margin-top: 26px;
    }
}

.scop-progress-bar {
    &__container {
        width: 380px;
        height: 6px;
        border-radius: 24px;
        background: $grey200;
    }

    &__progress {
        height: 100%;
        border-radius: 24px;
        background: $darkGreen;
    }
}
