.chat-summary {
    &__title {
        color: $black800;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: fit-content;

        &:hover {
            color: $PrimaryDarker;
        }
    }

    &__body {
        padding-left: 16px;
    }

    &__chevron {
        transition: rotate 0.3s;
        rotate: 10deg;
        &.--open {
            rotate: 100deg;
        }
    }
}