.generateButton {
    &__button {
        position: absolute;
        gap: 4px;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 48px;
        border: 1px solid;
        border-color: $grey300;
        background: white;
        box-shadow: 0px 0px 15px 0px rgba(106, 122, 158, 0.1);
        padding: 4px 16px;
        color: $grey500;
        cursor: pointer;
        transition: all 0.3s ease;
        z-index: 5;
        &.show {
            -webkit-animation: slide-top 0.5s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
        &.remove {
            -webkit-animation: slide-bottom 0.5s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
                both;
        }
        &.hidden {
            display: none;
        }
        &:hover {
            border: 1px solid #d3e1ff;
            border-color: #d3e1ff;
            color: $Primary;
        }
    }
    &__tooltip {
        border-radius: 6px;
        background: $black800;
        color: white;
        padding: 4px 8px;
        &.smallChat {
            max-width: 250px;
            text-align: center;
        }
    }
    &__tooltip-container {
        box-shadow: 0px 2px 10px 0px rgba(49, 63, 82, 0.21) !important;
    }
    &__tooltip-root {
        z-index: 1400 !important;
    }
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translate(-50%, 51px);
        transform: translate(-50%, 51px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        opacity: 1;
    }
}
@keyframes slide-top {
    0% {
        -webkit-transform: translate(-50%, 51px);
        transform: translate(-50%, 51px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        opacity: 1;
    }
}
@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate(-50%, 51px);
        transform: translate(-50%, 51px);
        opacity: 0;
        display: none;
    }
}
@keyframes slide-bottom {
    0% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate(-50%, 51px);
        transform: translate(-50%, 51px);
        opacity: 0;
        display: none;
    }
}
