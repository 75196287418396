.searchSuggestions {
    &__popper_bottom {
        margin-top: 27px !important;
        margin-left: -20px !important;
        z-index: 1301;
    }
    &__popper_top {
      margin-bottom: 27px !important;
      margin-left: -20px !important;
      z-index: 1301;
    }
    &__container {
        border-radius: 8px;
        background: white;
        box-shadow: 0px 0px 20px 0px rgba(106, 122, 158, 0.2);
        width: 420px;
        padding: 11px 0;

        &.--small-chat {
            width: 329px;
        }
    }
    &__option {
        color: $black800;
        cursor: pointer;
        padding: 4px 10px 4px 20px;
        transition: background 0.3s ease;
        height: 32px;
        line-height: 24px !important;
        &.hover {
            background: $Grey_5;
        }
    }
}
