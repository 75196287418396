.ingestModal {
    &__side-panel {
        &__header {
            align-items: normal !important;
            color: $black800;
        }
    }
}
.ingestModalTitle {
    &__title {
        gap: 10px;
        margin-bottom: 5px;
        &__subtitle {
            margin-bottom: 11px;
        }
        &__count {
            color: $grey500;
        }
    }
}
.ingestModalBody {
    &__add-persona {
        padding: 27px 50px 28px 32px;
        gap: 13px;
        color: $black800;
        border-top: 1px solid $grey100;
        background: white;
    }
    &__persona-popper {
        z-index: 10000;
    }
}
