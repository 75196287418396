.NoModelMessage {
    &__container {
        @extend .slide-message-up;
        background: $grey50;
        padding: 24px 32px;
        border-radius: 0px 16px 16px 16px;
        max-width: 80%;
    }
    &__header {
        color: $black800;
    }
    &__models {
        gap: 8px;
        margin: 16px 0;
    }
    &__model {
        padding: 2px 16px;
        background: rgba(255, 255, 255, 0.5);
        color: $Primary;
        border-radius: 50px;
        cursor: pointer;
        font-weight: 400 !important;
        max-width: fit-content;
        border: 1px solid $grey200;
        line-height: 28px !important;
        &--disabled {
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;
        }
        &--chosen {
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}
