@import '../../styles/Variables.scss';
.search {
    width: 100%;
    & div {
        background: #fff;
        border-color: $grey200 !important;
        overflow: hidden;
    }
}

.chevron {
    cursor: pointer;
    align-self: center;
    transition: rotate 0.3s;
    &.--open {
        rotate: 180deg;
    }
}
