.editResponsesModal {
    &__side-panel {
        & > div {
            width: 50vw !important;
        }
    }
    &__body {
        padding: 0 !important;
        overflow: hidden !important;
        font-family: 'Noto Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.1px;
    }
    &__upload-body {
        background-color: $grey50;
    }
    &__side-panel {
        z-index: 1209;
        &__header {
            color: $black800;
        }
    }
    &__container {
        padding: 30px;
        color: $black800;
        gap: 24px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    &__responses {
        gap: 42px;
    }
}
