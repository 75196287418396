.emailValidationModal {
    &__body {
        background: $grey50;
    }
    &__footer {
        gap: 10px;
    }
    &__title {
        gap: 8px;
        &__icon {
            color: #ff6262;
        }
        &__text {
            max-width: 580px;
        }
    }
    &__hidden-data {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        &.show {
            max-height: 600px;
            transition: max-height 1s ease-in-out;
        }
    }
    &__emails {
        margin: 10px 0 15px 32px;
        max-height: 150px;
        overflow-y: auto;
    }
    &__example {
        padding: 16px 24px;
        background: $grey100;
        border-radius: 8px;
        margin-left: 32px;
    }
    &__show-more {
        cursor: pointer;
        margin: 5px 0 20px 32px;
        color: $grey500;
        &__icon {
            transition: rotate 0.3s;
            &.open {
                transform: rotate(180deg);
            }
        }
    }
    &__checkBoxes {
        &__container {
            margin-top: 12px;
        }
        &__single-checkbox {
            & > p {
                color: $black800;
            }
        }
        &__persona {
            color: $grey500;
            margin-top: 3px;
            margin-left: 23px;
        }
    }
}
