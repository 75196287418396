@import '../../styles/Variables';

.menu {
    padding-top: 6px;
    background: $White;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(106, 122, 158, 0.15);
    max-width: 450px;
    min-width: 250px;
    &.--no-shadow {
        box-shadow: none;
    }

    &__item {
        font-size: 13px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 7px 20px;
        letter-spacing: 0.1px;
        transition: background-color 150ms ease-in, opacity 150ms ease-in,
            color 150ms ease-in, background-size 700ms;
        background-image: linear-gradient(
            90.03deg,
            #e3eefe 0.58%,
            #fefeff 110.31%
        );
        background-size: 0 100%;
        background-position: 0 center;
        background-repeat: no-repeat;
        color: $black800;

        &.--selected {
            background: linear-gradient(
                90.03deg,
                #e3eefe 0.58%,
                #fefeff 110.31%
            );
        }

        &.--disabled {
            cursor: not-allowed;
            opacity: 0.3;
        }

        &.--processing {
            cursor: wait;
            opacity: 0.3;
        }

        &:not(.menu__item.--disabled):hover {
            &:not(.menu__item.--selected) {
                background-color: $grey100;
            }

            &.menu__item.--selected {
                background-size: 130% 100%;
            }
        }

        &.menu__item:last-child {
            padding-bottom: 15px;
        }
    }

    &__divider {
        border-top: 1px solid $grey100;
        margin: 6px 16px;
    }
}
