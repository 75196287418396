.ModelList {
    &__models {
        gap: 8px;
        margin: 16px 0;
    }
    &__model {
        padding: 2px 16px;
        background: rgba(255, 255, 255, 0.5);
        color: $Primary;
        border-radius: 50px;
        cursor: pointer;
        font-weight: 400 !important;
        max-width: fit-content;
        border: 1px solid $grey200;
        line-height: 28px !important;
        &--disabled {
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;
        }
        &--chosen {
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}
