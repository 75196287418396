.personasSelection {
    &__persona-select-wrapper {
        background: white;
    }
    &__popper-footer {
        position: relative;
        color: $Primary;
        border-top: 1px solid $grey100;
        padding: 12px 0 16px 20px;
        cursor: pointer;
        &.disabled {
            cursor: default;
        }
        &.max-reached {
            cursor: default;
            opacity: 0.4;
        }
        &__loader {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(248, 249, 252, 0.6);
            position: absolute;
            z-index: 1;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;
        }
    }
    &__popper-header {
        color: $black800;
        border-bottom: 1px solid $grey100;
        padding: 17px 0 18px 20px;
        cursor: pointer;
    }
}
