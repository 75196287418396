.search-bar-border-container {
    margin: 1px;
    &:hover:not(.--disabled),
    &:focus-within:not(.--disabled) {
        margin: 0;
    }
}
.search-bar {
    display: flex;
    width: 100%;
    background: #fff;
    border: 1px solid $grey200;
    border-radius: 28px;
    padding-right: 11px;

    &:hover:not(.--disabled),
    &:focus-within:not(.--disabled) {
        background: linear-gradient(white, white) padding-box,
            linear-gradient(
                    269.15deg,
                    rgba(61, 129, 255, 0.4) 3.64%,
                    rgba(30, 199, 255, 0.4) 37.24%,
                    rgba(36, 115, 255, 0.4) 91.07%
                )
                border-box;
        border: 2px solid transparent;
    }

    &.--dark-stroke {
        border-color: $grey300;
    }

    &__action-right {
        min-height: 100%;
        background: $grey50;
        border-right: 1px solid $grey200;
        border-radius: 28px 0 0 28px;
        padding: 11px 19px 0 24px;
        display: flex;
        color: $black800;
        transition: background-color ease-out 300ms;
        align-items: baseline;

        &.--dark-stroke {
            border-color: $grey300;
        }

        &.--small {
            padding-top: 2px;
        }

        &.--opened {
            background: rgba(233, 240, 255, 1);
            color: $Primary;
        }

        &.--disabled {
            cursor: pointer;
        }

        &:hover:not(.--disabled) {
            color: $Primary;
            cursor: pointer;
        }
    }

    &__select {
        color: $Primary;
    }

    &__input {
        flex-grow: 1;
        vertical-align: middle;
        resize: none;
        margin: 16px 3px 16px 24px;
        min-height: 100%;
        font-size: 13px;
        line-height: 18px;
        background: none;
        box-sizing: border-box;
        font-family: NotoSans, sans-serif;
        color: $black800;
        outline: none;
        border: none;
        overflow-x: hidden;
        font-weight: 400;
        padding-right: calc(
            18px - $scrollBarWidth
        ); //scrollbar-gutter adds scrollbar width to the right padding
        scrollbar-gutter: stable;

        &::placeholder {
            transition: color 350ms;
            color: $grey500;
        }

        &.--rtl {
            direction: rtl;
        }

        &.--small {
            margin: 7px 3px 7px 24px;
        }
        /* width */
        &::-webkit-scrollbar {
            width: $scrollBarWidth;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #fff;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $grey300;
            border-radius: 24px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $grey200;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        align-self: flex-end;
        margin-bottom: 9px;

        &.--small {
            margin-bottom: 6px;
        }
    }

    &__reset {
        height: 24px;
        border-radius: 50px;
        background: $grey100;
        color: $grey500;
        opacity: 0;
        transition: all ease-out 300ms;

        &.--visible {
            opacity: 1;
            cursor: pointer;
        }

        &:hover {
            color: $Primary;
        }
    }

    &__send {
        color: $Primary;
        transition: all ease-out 300ms;
        cursor: pointer;

        &.disabled {
            color: $grey300;
            cursor: default;
        }
        &.--small {
            width: 20px;
            height: 20px;
        }

        &__tooltip {
            margin: 0 !important;
            display: flex;
            align-items: center;
            height: 32px;
            padding: 8px 16px !important;
            border-radius: 6px;
            background: $grey700 !important;
            box-shadow: 0px 2px 10px 0px rgba(3, 14, 28, 0.4);
            letter-spacing: 0.2px;
        }

        &__wrapper {
            display: inline-flex;
        }
    }
}
