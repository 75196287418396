@import '../../styles/Variables';

.model-select {
    &__tooltip {
        margin: 0 !important;
        display: flex;
        align-items: center;
        padding: 8px 16px !important;
        border-radius: 6px;
        background: $grey700 !important;
        box-shadow: 0px 2px 10px 0px rgba(3, 14, 28, 0.40);
        letter-spacing: 0.2px;

        & > * {
            font-weight: 400;
        }

        &__bold {
            font-weight: 700;
        }
    }

    &__tooltip-arrow {
        color: $grey700 !important;
    }
}
