@import url('https://fonts.googleapis.com/css?family=Noto+Sans:300,400,500,600,700,800&display=swap');
@import './Variables';
@import './globals';
@import './Animations';
@import './componentsStyle/ComponentsStyles';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: $fontFamily;
}

html {
    scroll-behavior: smooth;
    font-family: $fontFamily;
}

body {
    background-color: #f5f5f5;
}

#root {
    font-family: $fontFamily;
}

/* width */
 ::-webkit-scrollbar {
    width: $scrollBarWidth;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $grey400;
    border-radius: 24px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $grey300;
}



