.side-panel {
    &__container {
        & > div {
            width: 80% !important;
        }
    }
    &__upload-container {
        & > div {
            width: 530px !important;
        }
    }
    &__half-screen-container {
        & > div {
            width: 50% !important;
        }
    }

    &__header {
        color: $black800;
    }

    &__body {
        padding: 0 !important;
        overflow: hidden !important;
    }

    &__source-body {
        transition: padding 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        background-color: $grey300;
        &.--with-chat {
            padding-right: $smallChatWidth !important;
        }
    }

    &__upload-body {
        background-color: $grey50;
    }

    &__footer {
        background-color: #fff;
        padding: 0 16px;
        border-top: 1px solid $grey200;
    }

    &__footer-btns {
        display: flex;
        align-items: center;
        height: 60px;
        gap: 10px;
        margin-left: auto;
    }

    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 61px - 63px);
        background-color: rgba(248, 249, 252, 0.1);
        position: absolute;
        z-index: 1;
        width: 100%;
    }
}
