@import './styles/Variables.scss';
.home-container {
    background-image: url('../public/pub/scop_bg.jpg');
    background-size: cover;
    height: 100vh;
}


.home {
    overflow: hidden;
    overflow-y: auto;
    flex-basis: 100%;

    &__chat-container{
        width: 100%;
        height: 100%;
        max-width: 61%;
    }
}
