.personasProcessPendingModal {
    &__header-text {
        padding-left: 30px;
    }

    &__body {
        gap: 18px;

        &__text {
            max-width: 500px;
        }
    }

    &__warning-frame {
        padding: 8px 12px;
        background-color: $lightRed2;
        align-items: center;
        justify-content: center;
        max-width: 500px;
        gap: 3px;
        border-radius: 4px;

        &__icon {
            color: $darkRed;
        }
    }

    &__separator {
        border-bottom: 1px solid $grey100;
    }
}
