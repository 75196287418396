.headerMessage {
    &__container {
        padding: 15px 52px 13px 19px;
        color: $black800;
        background-color: $yellow300;
        gap: 9px;
    }
    &__text {
        line-height: initial !important;
    }
    &__icon {
        min-width: 24px;
    }
}
