@import '~aqui/alf.palette';

.view-floating-menu {
    &__label {
        color: $grey400;
        padding-right: 4px;
    }

    &__close-button {
        background-color: $grey700;
        margin-right: 9px;
        border-radius: 12px;
        cursor: pointer;

        &:hover {
            color: white;
            background-color: $grey800;
        }
    }

    &__delete:hover {
        color: $lightRed3;
    }
}
