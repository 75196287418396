.expertResponses {
    &__no-answer {
        color: $grey500;
    }
    &__inline-icon {
        display: flex;
        width: 24px;
        height: 24px;
        color: $Primary;
        cursor: pointer;
    }
    &__clickable-text-cell {
        cursor: pointer;
    }
}
