@import '../../styles/Variables';
.panel-container {
    &__container {
        max-width: 1295px;
        margin: 60px auto 0 auto;
        &.--full-width {
            max-width: 100%;
        }
    }
    &__section {
        position: relative;
        height: calc(100vh - 60px);
    }
    &__section:before {
        content: '';
        position: absolute;
        top: 0;
        right: 30px;
        bottom: 0;
        left: 9px;
        background: linear-gradient(
            125.06deg,
            rgba(26, 76, 253, 0.1) -2.31%,
            rgba(75, 223, 255, 0.1) 97.12%
        );
        filter: blur(25px);
        border-radius: 10px;
    }
    &__wrapper {
        display: flex;
        position: absolute;
        top: 12px;
        bottom: 0;
        left: 60px;
        right: 60px;
        background: white;
        border-radius: 8px 8px 0 0;
        &.--with-threads-open {
            left: 32px;
        }
    }
}
