.investigate-in-triage {
    @extend .slide-message-up;
    border: 3px solid #d3e1ff;
    border-radius: 16px;
    margin-top: 16px;
    max-width: 100%;
    &__container {
        border: 1px solid #a7c3ff;
        border-radius: 13px;
        padding: 23px 0px 22px 32px;
        transition: background-color 0.3s ease;
        &:hover:not(.disabled) {
            cursor: pointer;
            background: $grey50;
        }
        &.disabled {
            opacity: 0.4;
        }
    }
    &__text {
        white-space: pre-wrap;
    }
    &__button {
        width: fit-content;
        padding: 12px 24px;
        background: $Primary;
        color: white;
        transition: background-color 0.3s ease;
        border-radius: 6px;
        margin-top: 14px;
        &:hover:not(.disabled) {
            background: #3a77ff;
        }
        &.disabled {
            cursor: default;
            opacity: 0.4;
        }
    }
}
