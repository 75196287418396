.copilot-btn {
    &__container {
        position: absolute;
        right: 5px;
        top: 5px;
        width: 34px;
        height: 34px;
        background: $grey50;
        border-radius: 50%;
        cursor: pointer;
        &.open {
            background: #e9f0ff;
        }
        z-index: 3;
    }

    &__wrapper {
        position: relative;
        background-image: linear-gradient(
            234.52deg,
            #1ee0fa 14.14%,
            #0094ff 85.86%
        );
        z-index: 1;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        &.--is-large{
            width: 42px;
            height: 42px;
        }
    }

    &__wrapper::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(
            40.75deg,
            #1ee0fa 16.53%,
            #0094ff 84.46%
        );
        border-radius: 50%;

        z-index: -1;
        transition: opacity 0.5s linear;
        opacity: 0;
    }

    &__wrapper:hover::before {
        opacity: 1;
    }

    &__icon {
        // color: $primary;
        background: $grey50;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        &.--is-large{
            width: 38px;
            height: 38px;
            background: #fff;
        }
    }
    &__popper {
        z-index: 3;
        // font-family: $secondaryFontFamily;
        border-radius: 8px;
    }

    &__tooltip {
        background: rgba(255, 255, 255, 0.5);
    }

    &__icon {
        color: $Primary;
    }
}

.copilot-popper {
    &__container {
        background: white;
        box-shadow: 0px 4px 10px rgba(106, 122, 158, 0.15);
        border-radius: 8px;
        width: 380px;
    }
    &__header {
        color: $grey500;
        padding: 7px 0 7px 20px;
    }
    &__question {
        color: $black800;
        cursor: pointer;
        padding: 7px 16px 7px 20px;
        transition: background 0.7s ease;
        &:hover {
            background: $grey200;
        }
        & > svg {
            min-width: 24px;
            min-height: 24px;
        }
        &-text {
            max-width: 290px;
        }
    }
    &__editIcon {
        color: $grey500;
        border-radius: 100%;
        cursor: pointer;
        background-color: $grey50;
        transition: background-color 0.7s ease;
        &:hover {
            background: #fff;
            color: $Primary;
        }
        & > svg {
            min-width: 24px;
            min-height: 24px;
        }
    }
    &__skeleton {
        font-size: 13px;
        display: flex;
        padding: 16px 20px;
    }
    &__selectedQuestion {
        border-bottom: 1px solid #eceff6;
        padding: 15px 20px;
    }
    &__selectedQuestionLabel {
        font-size: 13px;
        font-family: NotoSans, sans-serif;
    }
    &__loading {
        margin-left: 9px;
        color: $grey500;
    }
}
