.suggestQuestionMessage {
    &__container {
        @extend .slide-message-up;
        background: $grey50;
        padding: 24px 32px;
        border-radius: 0px 16px 16px 16px;
        max-width: 80%;
        margin-top: 16px;
        position: relative;
        z-index: 1;
    }
    &__header {
        color: $black800;
        margin-bottom: 16px;
    }
    &__suggested-wrapper {
        gap: 8px;
        .suggestion {
            padding: 2px 16px;
            background: rgba(255, 255, 255, 0.5);
            color: $Primary;
            border-radius: 50px;
            cursor: pointer;
            max-width: fit-content;
            border: 1px solid $grey200;
            line-height: 28px !important;
        }
    }
}
