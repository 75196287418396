.headerMessage {
    height: fit-content;
    
    &__container {
        padding: 15px 52px 13px 19px;
        color: $black800;
        background-color: $yellow300;
        gap: 9px;
        word-break: break-word;
    }
    &__model-disclaimer {
        padding: 15px 52px 13px 22px;
        min-height: 52px;
        border-radius: 0px;
        gap: 9px;
    }
    &__text {
        line-height: initial !important;
    }
    &__icon {
        min-width: 24px;
    }
    &__model-disclaimer-icon {
        min-width: 16px;
    }
}
