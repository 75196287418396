.smallChatHeader {
    &__header {
        background-image: url('../../../public/pub/smallChatHeader_bg.png');
        background-size: cover;
        &.minimized-header {
            background-image: none;
            cursor: pointer;
        }
        &-logo {
            margin-right: 15px;
            padding: 16px 0px 16px 16px;
            color: $grey500;
            &.minimized-header {
                padding: 12px 0px 12px 24px;
            }
            &:hover {
                color: $Primary;
            }
            .minimizedIcon {
                transition: transform 0.5s ease;
                transform: rotate(180deg);
                &.isMinimizedWithInput {
                    transform: rotate(0deg);
                }
            }
        }
        &-icons {
            gap: 15px;
            padding-right: 20px;
            .smallChatHeader-icon {
                cursor: pointer;
                color: $grey500;
                &:hover {
                    color: $Primary;
                }
                &.open {
                    color: $Primary;
                }
            }
        }
    }
}
