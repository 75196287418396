.answersNavigator {
    &__container {
        color: $grey500;
        gap: 8px;
        &.smallChat {
            margin-top: 16px;
        }
    }
    &__arrowIcon {
        &:hover:not(.disabled) {
            color: $Primary;
        }
        &.disabled {
            opacity: 0.4;
            cursor: default;
        }
    }
}
