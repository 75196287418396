.copyPersonaSourcesModal {
    &__body {
        background: $grey50;
        border-bottom: 1px solid $grey100;
        padding-top: 35px !important;
        height: 100px;
    }
    &__footer {
        gap: 10px;
    }
}
