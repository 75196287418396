.main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 72px;
    padding: 0 60px 0 12px;
    position: absolute;
    width: 100vw;

    &__left{
        gap: 12px;
    }
    &__right{
        gap: 14px;
        align-self: end;
        margin-bottom: 12px;
    }

    &__threads-button {
        transition: transform 0.3s ease-in-out;
        cursor: pointer;
        color: $grey300;
        &:hover {
           color: $grey50;
        }
        &.--rotated {
            transform: rotate(180deg);
        }
    }

    &__customer-logo {
        img {
            max-width: 105px;
            max-height: 50px;
        }
    }

    &__new-chat-button {
        color: white;
        width: fit-content;
        padding: 4px 16px 4px 4px;
        border-radius: 46px;
        cursor: pointer;
        position: relative;
        background-image: linear-gradient(88deg, #2468FF 1.25%, #1EAEFF 99.18%);
        z-index: 1;
        animation-name: fadeIn;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 0.5s;
        &.--disabled {
            cursor: not-allowed;
        }
        &:hover:not(.--disabled)::before {
            opacity: 1;
          }
      }

      &__new-chat-button::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 46px;
        background: linear-gradient(88deg, #1EAEFF 1.25%, #2468FF 99.18%);
        z-index: -1;
        transition: opacity 0.5s linear;
        opacity: 0;
      }

      &__menu{
        min-width: 278px;
        padding-top: 14px !important;
        &__separator {
            height: 1px;
            background-color: $grey100;
            width: 100%;
            margin: 11px 0 3px 0;
        }
        &__row-right-component {
            margin-left: auto;
            padding-left: 45px;
        }
        &__non-selectable-item{
            display: flex;
            align-items: center;
            padding: 0 20px;
            min-height: 32px;
            cursor: default;
        }
      }

      &__menu-logo{
        background-color: $grey50;
        height: 47px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin-top: 6px;
      }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}