.wave-animiation {
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderSkeleton;
    animation-timing-function: linear;
    background: #f6f7f8;
    background-image: -webkit-linear-gradient(
        left,
        #f6f7f8 0%,
        #edeef1 20%,
        #f6f7f8 40%,
        #f6f7f8 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
}

@keyframes placeholderSkeleton {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes skeletonAnimate {
    from {
        background-position: top left;
    }
    to {
        background-position: top right;
    }
}

.slide-message-up {
    -webkit-animation: slide-message-up 0.4s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-message-up 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-right {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-left {
    -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(-500px);
        transform: translateX(-500px);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes slide-right {
    0% {
        -webkit-transform: translateX(-500px);
        transform: translateX(-500px);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@-webkit-keyframes slide-left {
    0% {
        -webkit-transform: translateX(500px);
        transform: translateX(500px);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes slide-left {
    0% {
        -webkit-transform: translateX(500px);
        transform: translateX(500px);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@-webkit-keyframes slide-message-up {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes slide-message-up {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.show-from-left {
    -webkit-animation: scale-up-hor-left 0.4s
        cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-hor-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-hor-left {
    0% {
        -webkit-transform: scaleX(0.4);
        transform: scaleX(0.4);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
    }
}
@keyframes scale-up-hor-left {
    0% {
        -webkit-transform: scaleX(0.4);
        transform: scaleX(0.4);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
    }
}

.rotate-vert-center {
    -webkit-animation: scale-up-ver-center 0.5s
        cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-ver-center 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-ver-center {
    0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}
@keyframes scale-up-ver-center {
    0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}

.slide-up {
    animation: slide-up 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-up {
    0% {
        padding-top: 24px;
        padding-bottom: 0;
    }
    100% {
        padding-top: 0;
        padding-bottom: 24px;
    }
}

.skeletonLoader {
    animation: skeletonLoader 2.3s linear infinite;
}

@keyframes skeletonLoader {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid $Primary;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

.loading-container {
    animation: pulse 1.5s ease-in-out infinite alternate;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        opacity: 0.5;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1.05);
    }
}

.circle-loader {
    width: 16px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #2468ff 94%, rgba(0, 0, 0, 0))
            top / 2px 2px no-repeat,
        conic-gradient(rgba(0, 0, 0, 0) 30%, #2468ff);
    -webkit-mask: radial-gradient(
        farthest-side,
        rgba(0, 0, 0, 0) calc(100% - 2px),
        #000 0
    );
    animation: l13 1s infinite linear;
}
@keyframes l13 {
    100% {
        transform: rotate(1turn);
    }
}

.dots::after {
    content: '';
    animation: dots 1.5s steps(4, end) infinite;
}

@keyframes dots {
    0%,
    20% {
        content: '';
    }
    40% {
        content: '.';
    }
    60% {
        content: '..';
    }
    80%,
    100% {
        content: '...';
    }
}
