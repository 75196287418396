@import '../../styles/Variables';

.filter-select {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 28px;
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    transition: border-color 250ms ease-in;

    &:hover:not(.--disabled) {
        cursor: pointer;
    }

    &.--bold {
        font-weight: bold;
    }

    &.--processing {
        cursor: wait;
    }

    &__label{
        margin-right: 5px;
    }

    &__chevron-icon {
        width: 16px;
        transition: transform 0.3s;
        &.--flipped {
            transform: rotateX(180deg);
        }
    }

    &__select-header {
        padding: 14px 20px 8px 20px;
        color: $grey500;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
    }
    &__select-values {
        max-height: 272px;
        overflow-y: auto;
        /* width */
        &::-webkit-scrollbar {
            width: $scrollBarWidth;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #fff;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $grey400;
            border-radius: 24px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $grey300;
        }
    }
    &__selected-filter {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
    }

    &__footer-message {
        display: flex;
        align-items: center;
        justify-content: left;
        height: 40px;
        background-color: $grey50;
        border-top: 1px solid $grey200;
        color: $black800;
        padding: 0 20px;
        border-radius: 0 0 8px 8px;
    }

    &__search{
        padding: 0 20px;
        width: 400px;
        margin-top: 16px;
        margin-bottom: 12px;
    }

    &__menu{
        width: 400px;
        min-height: 104px;
    }

    &__static-bottom-row{
        border-top: 1px solid $grey200;
        padding: 10px 20px;
    }

    &__no-values-message{
        display: flex;
        align-items: center;
        justify-content: center;
        color: $grey500;
        padding: 17px 0 23px 0;
    }
}
