.failedImport {
    &__body {
        background: $grey50;
        max-width: 700px;
        border-bottom: 1px solid $grey50;
        & > div {
            padding-right: 0 !important;
        }
    }
    &__title {
        gap: 8px;
        &__icon {
            color: #ff6262;
        }
    }
    &__hidden-data {
        overflow: hidden;
        max-height: 600px;
    }
    &__reasons {
        margin: 5px 0 20px 32px;
        max-height: 150px;
        overflow-y: auto;
        padding-right: 20px;
        & > p:not(:last-child) {
            margin-bottom: 3px;
        }
    }
}
