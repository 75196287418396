.data-management {
    &__header {
        display: flex;
        align-items: center;
        gap: 4px;
        color: $black800;
        margin: 30px 0 34px 24px;
    }
    &__back-btn {
        transition: color 0.3s;
        cursor: pointer;
        &:hover {
            color: $Primary;
        }
    }
    &__tabs {
        width: fit-content;
        margin-left: 53px;
        border-bottom: none;
        &__loading {
            gap: 6px;
            margin-bottom: 10px;
            height: 16px;
            margin-right: 38px;
            color: $grey500;
        }
        &__loading-text {
            width: 130px;
        }
    }
    &__body {
        background-color: $grey50;
        height: 100%;
        overflow: hidden;
    }
    &__filter {
        border-radius: 6px;
        border: 1px solid $grey300;
        padding: 0 8px 0 12px;
        transition: all 0.3s;
        color: $black800;
        & svg {
            width: 16px;
            height: 16px;
        }
        &.--active:not(.--disabled, .--processing),
        &:hover:not(.--disabled, .--processing) {
            background-color: $primary10;
            border-color: #bdd2ff;
            color: $Primary;
        }
        &.--disabled {
            cursor: not-allowed;
            color: $grey400;
        }
        &.--processing {
            cursor: progress;
            color: $grey400;
        }
        &.--semi-active {
            color: $blue100 !important;
        }

        &__label {
            max-width: 64px;
        }
    }
}

.data-management-table {
    display: flex;
    flex-direction: column;
    gap: 21px;
    padding: 30px 34px 0 53px;
    height: 100%;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__section {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    &__search {
        width: 227px;
    }
}
