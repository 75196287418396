$inputPaddingBottom: 24px;

.chat {
    &__container {
        background: white;
        flex: 2;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        &.smallChatWidth {
            max-width: $smallChatWidth;
            border-radius: 16px;
        }
    }

    &__wrapper {
        height: 100%;
    }
    &__conversation {
        position: relative;
        gap: 24px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 24px calc(48px - $scrollBarWidth) 50px; //scrollbar-gutter both-edges adds scrollbar width to the left and right padding
        scrollbar-gutter: stable both-edges;
        @extend .scrollbar;
        &.--smallChat {
            padding: 11px calc(27px - $scrollBarWidth) 50px 22px; //scrollbar-gutter adds scrollbar width to the right padding
            overflow-x: hidden;
            overflow-y: auto;
            scrollbar-gutter: stable;
        }
        &.minimized {
            display: none;
        }
    }
    &__input {
        position: relative;
        margin-top: auto;
        padding: 24px 58px;
        box-shadow: 0px -2px 30px rgba(106, 122, 158, 0.1);
        &.--smallChat {
            padding: 0 32px $inputPaddingBottom 37px;
        }
        &.minimized {
            box-shadow: none;
            padding: 0 24px $inputPaddingBottom 24px;
        }
    }
    &__model-actions {
        display: flex;
        align-items: center;
        min-height: 55px;
        border-radius: 8px 0px 0px 0px;
        border-bottom: 1px solid $grey100;
        padding: 0 22px;
        justify-content: space-between;

        &__chevron {
            transition: rotate 0.3s;
            .--active & {
                rotate: 180deg;
            }
        }
    }
    &__date {
        position: relative;
        &__line {
            position: absolute;
            left: -60px;
            right: -60px;
            height: 1px;
            background-color: $grey200;
        }
        &__text {
            z-index: 1;
            padding: 0 8px;
            background-color: white;
        }
    }
    &__summary {
        display: flex;
        justify-content: flex-end;
    }
}

.skeleton-chat {
    height: 77px !important;
}

.aq-genai-root {
    box-sizing: content-box !important;
    position: fixed;
    bottom: 0px;
    right: 30px;
    background: #ffffff;
    border: 1px solid $grey200;
    border-radius: 0px;
    z-index: 999;
    width: 283px;
    box-shadow: 0px 0px 44px rgba(27, 77, 204, 0.1);
    border-radius: 16px 16px 0px 0px;
    &.--initial {
        animation: initialized 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        &.smallScreenNoInput {
            min-height: 47px !important;
        }
    }

    &.--minimized {
        animation: minimized 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        &.smallScreenNoInput {
            min-height: 47px !important;
        }
    }

    &.--maximized {
        right: 0;
        border-radius: unset;
        width: $smallChatWidth;
        animation: maximized 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        box-shadow: none;
    }
    &.--hidden {
        display: none;
    }
    &.--in-source-modal {
        max-height: calc(100vh - 47px);
        border: none;
        height: 100vh;
        right: 0px;
        width: $smallChatWidth;
        border-radius: 0;
        z-index: 1301;
    }
    &.--collapse-chat {
        animation: collapseChat 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
    &.--show-chat {
        animation: showChat 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
}

.collapse-btn {
    border-radius: 100%;
    cursor: pointer;
    border: 2px solid $grey50;
    color: $grey500;
    transition: color 0.3s;
    width: fit-content;
    background: #fff;
    &:hover {
        color: $Primary;
        .collapse-btn__inner {
            border-color: $Primary;
        }
    }

    &--in-source-modal {
        position: absolute;
        right: calc($smallChatWidth - 16px);
        bottom: $inputPaddingBottom;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 100%;
        border: 1px solid $grey100;
        transition: border-color 0.3s;
    }
}

@keyframes minimized {
    0% {
        height: 100vh;
        right: 0;
        width: $smallChatWidth;
    }
    100% {
        min-height: 106px;
        right: 30px;
        width: 283px;
    }
}
@keyframes maximized {
    0% {
        height: 208px;
        right: 30px;
        width: 283px;
    }
    100% {
        height: 100vh;
        right: 0px;
        width: $smallChatWidth;
    }
}
@keyframes initialized {
    0% {
        transform: translateY(500px);
    }
    100% {
        transform: translateY(0);
        min-height: 106px;
    }
}

@keyframes collapseChat {
    0% {
        right: 0;
    }
    100% {
        right: -$smallChatWidth;
    }
}

@keyframes showChat {
    0% {
        right: -$smallChatWidth;
    }
    100% {
        right: 0;
    }
}
