.chatMessage {
    &__container {
        color: $black800;
        &.User,
        &.UserMock {
            display: flex;
            justify-content: flex-end;
        }
    }
    &__message-container {
        max-width: 100%;
        position: relative;
        z-index: 5;
        &.--smallChat {
            max-width: 100%;
        }
        &.Aquant {
            @extend .slide-message-up;
        }
        &.User,
        &.UserMock {
            @extend .slide-message-up;
        }
    }
    &__message {
        max-width: 100%;
        padding: 12px 32px;
        white-space: pre-wrap;
        word-break: break-word;
        &.User,
        &.UserMock {
            justify-content: flex-end;
            background: $lightgreen2;
            border-radius: 16px 0px 16px 16px;
        }
        &.Aquant {
            justify-content: start;
            background: $grey50;
            border-radius: 0px 16px 16px 16px;
            padding: 24px 32px;
            position: relative;
            z-index: 2;

            &.--with-rewrite-feedback {
                border-radius: 0 16px 0 0;
            }
            &.--with-rewrite-feedback:not(.--smallChat) {
                padding: 24px 32px 20px;
            }
        }
        &.--smallChat {
            padding: 12px 24px;
            font-size: 12px;
        }
        &__error {
            border-radius: 8px;
            border: 1px solid;
            border-color: $red300;
            padding: 12px;
            margin-top: 16px;
            flex: 1;
            background-color: $red100;
            &__text {
                color: $red500;
                padding-left: 8px;
            }
        }
        &__footer {
            flex-wrap: wrap;
            row-gap: 10px;
            margin-top: 16px;
            &__icons {
                gap: 10px;
                color: $grey500;
                height: 24px;
                overflow: hidden;
                margin-left: auto;
                .copy-to-clipboard {
                    padding-top: 24px;
                    &:hover {
                        color: $Primary;
                        cursor: pointer;
                    }
                    & > svg:last-child {
                        color: $Primary;
                        cursor: default;
                    }
                }
                .edit-answer {
                    gap: 5px;
                    color: $grey500;
                    cursor: pointer;
                    &:hover {
                        color: $Primary;
                    }
                }
            }
            &__text {
                color: $Primary;
                cursor: pointer;
                & > svg {
                    width: 18px;
                    height: 18px;
                    margin-left: -3px;
                    transition: transform 0.5s ease-in-out;
                }
                &.--smallChat {
                    color: $grey500;
                }
            }
        }
    }
    &__source-btns {
        display: inline-flex;
        align-items: center;
        &__edit {
            height: 20px;
            width: 20px;
            color: $grey500;
            cursor: pointer;
            opacity: 0;
            transition: opacity 0.3s;
            margin-bottom: -5px;
            &:hover {
                color: $Primary;
            }
            &.--show {
                opacity: 1;
            }
            &.--relative {
                position: relative;
                top: 6px;
                margin-left: 6px;
            }
        }
        &__lone-edit {
            display: inline-flex;
            position: relative;
            top: 5px;
            left: 5px;
        }
        &__edited-and-edit {
            display: inline-flex;
            & > svg {
                position: relative;
                top: 5px;
                left: 5px;
            }
        }

        &__edited {
            width: 20px;
            height: 20px;
            background: linear-gradient(39deg, #adddff 19.23%, #c9f5ff 81.12%);
            border-radius: 50%;
            position: relative;
            top: 5px;
        }
    }
    &__segemnt {
        &:hover {
            .chatMessage__source-btns__edit {
                opacity: 1;
            }
        }
    }
}

.chatMessage__chevron-open {
    transform: rotate(180deg);
}

.chatMessage__source-number {
    min-width: 20px;
    height: 20px;
    padding: 2px 4px;
    border-radius: 50%;
    background: $grey100;
    transition: opacity 300ms;
    color: $Primary;
    margin-right: 6px;
    text-align: center;
    font-size: 12px;
    display: inline-block;
    line-height: 16px;
    cursor: pointer;
    &:hover {
        color: $White;
        background: $Primary;
    }
    &.--disabled {
        opacity: 0.4;
        cursor: not-allowed;
        &:hover {
            color: $Primary;
            background: $grey100;
        }
    }
    &.--active {
        background: rgba(232, 0, 255, 0.1);
        color: $black800;
    }
}

.message-disclaimers {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    @extend .slide-message-up;

    &__items-container {
        display: flex;
        flex-direction: column;
        padding: 12px 11px;
        gap: 20px;
        border-radius: 0px 16px 16px 16px;
        &.--Danger {
            color: $darkRed;
            background-color: $lightRed2;
        }
        &.--Warning {
            color: #b25a09;
            background-color: #ffe8d3;
        }
        &.--Caution {
            color: #826500;
            background-color: #fff6d5;
        }
        &.--Notice {
            color: $darkBlue3;
            background-color: #e8f9ff;
        }
    }

    &__rest-disclaimers {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__show-more-btn {
        margin-top: 16px;
        height: 32px;
        padding: 0 13px 0 20px;
        border-radius: 8px;
        background: $grey50;
    }
    &__show-less-btn {
        margin-top: 16px;
        svg {
            margin-top: 1px;
            rotate: 180deg;
        }
    }

    &__item {
        display: flex;
        gap: 5px;
        word-break: break-word;
        & > svg {
            min-width: 16px;
            margin-top: 2px;
        }
    }

    &__link {
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
}
