/* stylelint-disable color-function-notation */
@use 'sass:color';
@import '../../styles/Variables.scss';

$containerBackground: $grey6;
$disabledSubLabel: rgba(180, 185, 196, 0.701);

.data-grid-table {
    position: relative;
    overflow: auto;

    &.no_results {
        flex: 1;
    }
}

.--disabled {
    cursor: not-allowed;
}

.data-grid {
    flex: 1;
    overflow: auto;

    &__row {
        background-color: $white;
        color: $textDefault;
        font-family: $fontFamily;

        &.--highlight {
            animation: highlightRowBlue 800ms ease-in 2;
        }

        &.--deleted {
            background: $grey200;

            &:hover {
                background: $grey50;
            }
        }

        &.--disabled {
            pointer-events: none;
        }

        &.--disabled-unmerge-sub-label {
            background-color: $disabledSubLabel;
        }
    }

    &__cell {
        &.--deleted,
        &.--disabled {
            opacity: $opacityDisabled;
        }
        &.--noPadding {
            padding: 0 !important;
        }
    }

    &__header {
        font-family: $fontFamily;
        color: $textSecondary;
        border-right: 1px solid $grey100;
        border-bottom: 1px solid $grey100;
        background-color: $grey50;

        &:hover {
            background: $grey100;
        }
    }

    &__sort-icon {
        color: $textSecondary;
    }
}

:global {
    .MuiDataGrid-root {
        background: white;
    }

    .MuiDataGrid-columnHeaders {
        z-index: 2;
    }

    .MuiDataGrid-virtualScroller {
        &::-webkit-scrollbar {
            position: absolute;
            width: 12px;
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background: $grey300;
            border-radius: 40px;

            &:hover {
                background: $grey400;
            }
        }
    }
}

@keyframes highlightRow {
    0% {
        background-color: auto;
    }

    50% {
        background-color: color.mix($containerBackground, $orange, 10%);
    }

    100% {
        background-color: auto;
    }
}

@keyframes highlightRowBlue {
    0% {
        background-color: auto;
    }

    50% {
        background-color: color.mix($containerBackground, $darkBlue, 10%);
    }

    100% {
        background-color: auto;
    }
}

:export {
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey600: $grey600;
    grey700: $grey700;
    grey800: $grey800;
}
