.checkboxSourceList {
    &__container {
        display: flex;
        flex-direction: column;
        padding: 20px 30px;
        gap: 12px;
        overflow-y: auto;
    }
    &__item-name {
        width: calc(100% - 22px);
        &__main {
            color: $black800;
            font-size: 14px !important;
            font-weight: 700 !important;
        }
        &__secondary {
            color: $grey500;
            margin-top: 4px;
        }
    }
}
