@import '~aqui/alf.palette';
$Aquant_new_brand_color: #2468ff;
$Black: #1f1f1f;
$Black_2: #4a4a4a;
$Grey_1: #818183;
$Grey_2: #999ca1;
$Grey_3: #bfc4ca;
$Grey_4: #dadcdf;
$Grey_5: #edeff5;
$Grey_6: #f6f7f7;
$Grey_7: #edeff580;
$Grey_8: #f0f2f5;
$Grey_9: #a3aabb;
$White: #ffffff;
$Green_1: #def7e3;

$AppBackground: #ffffff;

$fontFamily: 'Noto Sans', Arial, sans-serif;

// new palette
$black800: #050f29;
$black900: #000000;
$green200: #def7e3;
$green300: #a9f0b9;
$green600: #276859;
$darkGreen: #76ca95;
$grey50: #f8f9fc;
$grey100: #eceff6;
$grey200: #e6e9f2;
$grey300: #d0d5e0;
$grey400: #a3aabb;
$grey500: #6e768a;
$grey600: #596175;
$grey700: #333a4d;
$grey800: #272c3a;
$grey900: #1d212b;
$red100: #ffedec;
$red200: #ffe0e8;
$red300: #ffd1dc;
$red400: #ff8aa7;
$red500: #c70030;
$red700: #580116;
$darkRed: #da4444;
$yellow300: #f5ca80;
$lightgreen2: #e0f8e9;

$Primary: #2468ff;
$PrimaryDarker: #2161ed;
$primary10: #e9f0ff;
$primary20: #d3e1ff;
$primary40: #a7c3ff;
$primary60: #7ca4ff;
$Orange: #f4ac48;

.Rotate {
    transform: rotate(-90deg);

    /* Safari */
    -webkit-transform: rotate(-90deg);

    /* Firefox */
    -moz-transform: rotate(-90deg);

    /* IE */
    -ms-transform: rotate(-90deg);

    /* Opera */
    -o-transform: rotate(-90deg);

    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

$scrollBarWidth: 8px;
$smallChatWidth: 400px;
$opacityDisabled: 0.4;
