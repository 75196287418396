.skeleton-group {
    gap: 6px;
}
.skeleton {
    position: relative;
    width: 100%;
    min-height: 24px;
    overflow: hidden;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
            101.53deg,
            #eceff6 14.7%,
            #f1f2f5 49.99%,
            #eceff6 86.03%
        );
        border-radius: 4px;
    }
    &.dark::before {
        background: #6a779b1f;
    }
    &::after {
        @extend .skeletonLoader;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
            97.76deg,
            #edf0f7 13.13%,
            #ffffff 48%,
            #edf0f7 77.51%
        );
    }
    &.dark::after {
        background: linear-gradient(97.76deg, #6a779b00, #6a779b2e, #63729b00);
    }
}

.chat-bubble {
    background-color: $grey50;
    padding: 16px 28px;
    border-radius: 0 16px 16px 16px;
    display: inline-block;
    width: fit-content;
}
.typing {
    align-items: center;
    display: flex;
    height: 17px;
}
.typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: $Primary; //rgba(20,105,69,.7);
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
}
.typing .dot:nth-child(1) {
    animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
    animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
    animation-delay: 400ms;
}
.typing .dot:last-child {
    margin-right: 0;
}

@keyframes mercuryTypingAnimation {
    0% {
        transform: translateY(0px);
        background-color: #2468ffb5;
    }
    28% {
        transform: translateY(-7px);
        background-color: #2468ff82;
    }
    44% {
        transform: translateY(0px);
        background-color: #2468ff47;
    }
}
