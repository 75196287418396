.CodeMirror {
    border: 1px solid $Primary !important;
    border-radius: 0 !important;
    border-bottom: none !important;
    border-top: none !important;
}
.editor-toolbar {
    border: 1px solid $Primary !important;
    border-bottom: 1px solid $grey200 !important;
}

.markdown-br {
    height: 10px;
    &.small-chat {
        height: 4px;
    }
}

.markdown-container {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        height: 8px;
    }
    table {
        font-size: 12px;
        border-collapse: separate;
        border-spacing: 0;
        max-width: 100%;
        background-color: transparent;
        border-radius: 0.5rem;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        width: 100%;
        min-width: max-content;
    }

    th,
    td {
        padding: 8px;
        text-align: left;
        vertical-align: middle;
        border-bottom: 1px solid #e0e0e0;
    }

    th {
        background-color: $grey100;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 0.9em;
        color: $grey500;
    }

    tr {
        &:last-child td {
            border-bottom: none;
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: #f8f8f8;
                transition: background-color 0.3s ease;
            }

            &:nth-child(even) {
                background-color: #fafafa;
            }
        }
    }

    h1 {
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.2px;
    }
    h2 {
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
    }
    h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.1px;
    }
    h4 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.2px;
    }
    h5 {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.2px;
    }
    h6 {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.1px;
    }
    ul {
        padding-top: 6px;
        padding-left: 40px;
        list-style: revert;
        display: flex;
        flex-direction: column;
        li {
            list-style-type: disc;
            ul {
                li {
                    list-style-type: circle;
                }
            }
        }
    }
    ol {
        padding-left: 40px;
        list-style: revert;
        display: flex;
        flex-direction: column;
        ul {
            list-style-type: disc;
            ul {
                list-style-type: circle;
            }
        }
    }
    li {
        list-style: revert;
        &:not(:last-child) {
            padding-bottom: 6px;
        }
    }
    strong {
        font-weight: revert;
    }
    em {
        font-style: italic;
    }
    a {
        color: $Primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.1px;
        text-decoration-line: underline;
        &:hover {
            text-decoration-line: none;
        }
    }
    code {
        font: revert;
    }
    &.small-chat {
        ul {
            padding-top: 4px;
        }
        li {
            &:not(:last-child) {
                padding-bottom: 4px;
            }
        }
    }
}
