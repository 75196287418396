.feedback-modal {
    &__icon {
        border-radius: 100%;
        background: $grey100;
        margin-right: 8px;
    }

    &__checkbox-container {
        display: flex;
        align-items: center;
        gap: 7px;
        margin: 0 auto 0 20px;
        cursor: pointer;
    }

    &__skip-btn {
        margin-right: 8px;
    }

    &__checkbox-label {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
    }

    &__body {
        background: $grey50;
    }

    &--grey {
        color: $grey500;
    }
    &--indication {
        color: $grey500;
        display: flex; 
        justify-content: flex-end;
    }

    &__categories {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        margin-bottom: 18px;
    }

    &__textarea-container {
        display: flex;
        flex-direction: column;
        gap: 11px;
        margin-bottom: 33px;

        .input-frame {
            flex-grow: 1;
            display: flex;
            align-items: center;
            padding: 0 3px;
            border-radius: 8px;
            transition: border-color 350ms, color 350ms;
            cursor: text;
            overflow: auto;
            color: $black800;
            border: 1px solid $grey200;

            &:focus-within,
            &:hover {
                border-color: #7ca4ff;
            }

            &__input {
                vertical-align: middle;
                resize: none;
                padding: 4px 3px;
                overflow-y: hidden;
                min-height: 80px;
                font-size: 13px;
                width: 100%;
                line-height: 18px;
                background: none;
                box-sizing: border-box;
                font-family: NotoSans, sans-serif;
                outline: none;
                color: inherit;
                outline: none;
                border: none;
                overflow: visible;
                font-weight: 300;
                &.--rtl {
                    direction: rtl;
                }
            }
        }
    }
    &__category-btn {
        display: flex;
        align-items: center;
        color: $Primary;
        padding: 0 16px;
        height: 34px;
        border-radius: 50px;
        border: 1px solid $grey200;
        cursor: pointer;
        transition: border-color 350ms, color 350ms;

        &:hover,
        &.--selected {
            background: $primary10;
            border-color: $primary20;
        }
    }
}
