.source-modal {
    &__header {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 80 * 10px;
    }
    &__navigator {
        margin: 0 30px 0 12px;
        color: $grey500;
        gap: 13px;
    }
    &__arrow {
        color: $grey500;
        transition: color 0.3s ease;
        &:hover:not(.disabled) {
            color: $Primary;
        }
        &.disabled {
            opacity: 0.4;
        }
    }
    &__download-btn{
        border-radius: 100%;
        color: $grey500;
        background-color: $grey100;
        margin: 0 auto 0 12px;
        min-width: 24px;

        cursor: pointer;
        &.--in-progress{
            cursor: progress;
            opacity: $opacityDisabled;
        }
        &:hover:not(.--in-progress){
            color: $Primary;
            background-color: $grey200;
        }

    }

    &__copilot-btn{
        position: absolute;
        cursor: pointer;
        bottom: 11px;
        right: 21px;
        width: fit-content;
        border-radius: 100%;
        border: 3px solid $grey50;
    }
}
