.summary-modal {
    & > div {
        width: 670px !important;
    }
    
    &__title {
        margin-bottom: 8px;
        font-weight: 500 !important;
        color: $black800;
        display: flex;
        align-items: center;
    }

    &__subtitle {
        color: $grey500;
    }

    &__separator {
        margin-bottom: 24px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        scrollbar-gutter: stable both-edges;
        background: $grey50;
    }

    &__footer {
        width: 100%;
        background: white;
        height: 60px;
        display: flex;
        padding: 18px 32px;
        align-items: center;
    }

    &__export-button {
        display: flex;
        color: $Primary;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
}
