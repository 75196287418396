.expertResponseLabel {
    &__container {
        margin-bottom: 16px;
        &.smallChat {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            gap: 8px;
        }
    }
    &__name {
        border-radius: 48px;
        background: linear-gradient(90deg, #c9f5ff 0%, #adddff 100%);
        gap: 4px;
        padding: 4px 16px 4px 8px;
        color: $black800;
    }
    &__date {
        color: $grey500;
    }
}
