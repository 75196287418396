@import '../../../styles/Variables.scss';

.ingest-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &.--Done {
        color: #2b7d31;
    }
    &.--Processing {
        color: $darkBlue2;
    }
    &.--Failed {
        color: $red;
    }
    &.--Uploading,
    &.--Uploaded {
        color: $Orange;
    }
    &.--selected {
        color: $grey200;
    }
}

.source-document-cell {
    color: $Primary;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
    &.--no-data {
        color: $grey500;
        pointer-events: none;
    }
    &.--selected {
        color: $grey200;
    }
}

.multi-select-cell {
    width: 100%;
    padding: 9px 16px;
    display: flex;
    align-items: center;
    gap: 4px;
    align-items: baseline;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.3s;
    &.--disabled {
        cursor: default;
        opacity: 0.4;
    }
    &:hover:not(.--disabled) {
        border: 1px solid $primary40;
        background: $grey50;
        cursor: pointer;
    }
}
.multi-select-cell-full {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 4px;
    margin: 20px;
    padding: 12px 8px;
    border: 1px solid #2468ff;
    border-radius: 4px;
}

.multi-select-cell-item {
    padding: 2px 8px;
    border-radius: 4px;
    background: $grey100;
    max-width: 80px;
    color: $black800;
}
.multi-select-cell-editable-item {
    display: flex;
    padding: 2px 0px 2px 8px;
    align-items: center;
    border-radius: 4px;
    background: $grey100;
    color: $black800;
    & > svg {
        cursor: pointer;
    }
}
.editable-chip {
    max-width: 80px;
}

.multi-select-cell-indicator {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: $grey100;
    color: $Primary;
}
.multi-select-cell-input {
    border: none;
    margin-top: 10px;
    background: transparent;
}
.multi-select-cell-popper-wrapper {
    z-index: 2;
}
.multi-select-cell-search-header {
    width: 340px;
}
.multi-select-cell-popper {
    width: 340px;
    max-height: 230px;
    border-radius: 6px;
    background: #fff;
}
.multi-select-cell-popper-select_all {
    padding: 0 0 17px 20px;
    border-bottom: 1px solid $grey100;
    color: $black800;
    cursor: pointer;
    &:hover {
        background: $Grey_6;
    }
}
.multi-select-cell-popper-body {
    padding: 8px 0;
    border-bottom: 1px solid $grey100;
    height: 120px;
    overflow-y: auto;
}
.multi-select-cell-popper-body-item {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    cursor: pointer;
    &:hover {
        background: $Grey_6;
    }
}
.multi-select-cell-popper-body-item-text {
    color: $black800;
    max-width: 320px;
}
.multi-select-cell-popper-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin: 14px 10px 18px 0;
}
.multi-select-cell-loading-personas {
    display: flex;
    align-items: center;
    color: $Orange;
}

.editable-cell {
    &__container {
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        padding: 12px 16px;
        transition: all 0.3s;
        border: 1px solid transparent;
        cursor: pointer;
        &:hover {
            border: 1px solid $primary40;
            background: $grey50;
        }
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
    }
    &__text {
        min-height: 18px;
        &.--placeholder {
            opacity:  $opacityDisabled;
        }
    }
    &__text-area {
        border: none;
        background: transparent;
        resize: none;
        margin: 16px;
        padding: 8px;
        border: 1px solid $grey300;
        border-radius: 4px;
        padding-top: 8px;
        font-size: 13px;
        font-weight: 500;
        outline: none;
        transition: border-color 350ms;
        &:hover,
        &:focus-within {
            border: 1px solid $Primary;
        }
    }
    &__btns {
        display: flex;
        justify-content: end;
        gap: 5px;
        margin-bottom: 16px;
        margin-right: 16px;
    }
    &__btn {
        cursor: pointer;
        min-height: 24px;
        min-height: 24px;
        border-radius: 50%;
        &.--cancel {
            border: 1px solid #7ca4ff;
            background: #e9f0ff;
            color: $Primary;
        }
        &.--apply {
            border: 1px solid $Primary;
            background: $Primary;
            color: white;
        }
        &.--disabled {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
}

.clickableCell {
    &__container {
        color: $Primary;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &:hover {
            .clickableCell__hidden-arrow {
                transition: opacity 0.3s;
                opacity: 1;
            }
        }
    }

    &__hidden-arrow{
        opacity: 0;
        height: 24px;
    }
}

.textCell {
    &__container {
        width: 100%;
        display: flex;
        align-items: center;
        &.icon-left {
            gap: 8px;
        }
        &.icon-right {
            justify-content: space-between;
        }
    }
}

.toggleCell {
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

.dropdownCell{
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;
    }

    &__chevron {
        transition: transform 0.3s;
        min-width: 24px;
        &.--open {
            transform: rotate(180deg);
        }
    }
}

.ChipsCell{
    &__container {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 4px;
        &.--disabled {
            pointer-events: none;
            opacity: 0.4;
        }
    }

    &__item {
        padding: 2px 8px;
        border-radius: 4px;
        background: $grey100;
        color: $black800;
        &__hidden-num {
            color: $Primary;
        }
    }
}
