.messageEditing {
    &__container {
        position: relative;
    }
    &__instructions {
        position: absolute;
        right: 5px;
        top: 10px;
        max-width: 180px;
    }
    &__message-text-area {
        @extend .scrollbar;
        width: 100%;
        display: block;
        padding: 16px 32px 0px 14px;
        border-radius: 8px 8px 0px 0px;
        border: 1px solid $Primary;
        border-bottom: none;
        background: white;
        resize: none;
        &:focus-visible {
            outline: none;
        }
        &__btns-container {
            position: relative;
            border: 1px solid $Primary;
            height: 34px;
            width: 100%;
            border-top: none;
            border-radius: 0px 0px 8px 8px;
            background: white;
        }
        &__btns {
            position: absolute;
            bottom: 10px;
            right: 20px;
            gap: 5px;
        }
        &__btn {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 1px solid $Primary;
            cursor: pointer;
            &.checkmark {
                background: $Primary;
                color: white;
                &.disabled {
                    opacity: 0.4;
                    cursor: default;
                }
            }
            &.crossmark {
                background: $primary10;
                color: $Primary;
                border: 1px solid $primary60;
            }
        }
    }
}
