.first-request {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: calc((100vh - 72px) * 0.22);
    &__heading {
        font-weight: 300;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.1px;
        color: $grey600;
        text-align: center;
    }

    &__input-container {
        margin-top: 32px;
        width: 700px;
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            z-index: -1;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(
                125.06deg,
                rgba(26, 76, 253, 0.1) -2.31%,
                rgba(75, 223, 255, 0.1) 97.12%
            );
            filter: blur(10px);
        }
    }

    &__loader {
        margin-top: 32px;
    }
}
.svg-container {
    width: 375px;
    height: 120px;
    display: inline-block;
    margin-top: -50px;
}
