.pdf-viewer {
    display: contents;
    flex-basis: 100%;

    &__document-container {
        overflow: auto;
        display: flex;
        height: 100%;
        background: $grey300;
        &::-webkit-scrollbar {
            width: $scrollBarWidth;
            height: $scrollBarWidth;
        }
        &::-webkit-scrollbar-thumb {
            background: $grey400;
            border-radius: 60px;
        }
    }

    &__document {
        margin: 0 auto;
        &--hide {
            display: none !important;
        }
    }

    &__message {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-height: 0;
        transition: max-height 0.5s, overflow 0s, padding 0.5s;
        background: #e9f0ff;
        padding: 0 0 0 26px;
        color: $black800;
        &--show {
            padding: 14px 34px 16px 26px;
            max-height: 50px;
        }
        &__percent {
            color: $grey500;
        }
    }
}

.pdf-page {
    margin-top: 16px;
    justify-content: center;
    display: flex;
    background-color: $grey300;
    &--hide {
        opacity: 0;
    }

    &--highlight {
        background: #ea00ff;
        color: transparent;
        margin: -4px;
        padding: 2px 4px;
        position: initial;
    }
}

.doc-control-panel {
    border-radius: 4px;
    background: $grey800;
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 40px;
    color: $grey300;
    position: absolute;
    top: 94%;
    left: 50%;
    transform: translate(-50%);
    &.--with-chat {
        left: calc(50% - $smallChatWidth);
        transform: translate(calc(-50% + $smallChatWidth / 2));
    }
    z-index: 3;
    cursor: default;
    user-select: none;

    &__pagination {
        display: flex;
        align-items: center;
        border-right: 1px solid $grey700;
        padding-right: 17px;
        gap: 8px;
    }

    &__scale {
        display: flex;
        align-items: center;
        padding: 0 14px 0 10px;
        gap: 4px;
        width: max-content;
        border-right: 1px solid $grey700;
    }

    &__rotate {
        display: flex;
        align-items: center;
        gap: 3px;
        padding: 0 2px 0 17px;
    }

    &__button {
        transition: opacity 300ms;
        cursor: pointer;
        border-radius: 2px;
        &:hover:not(.--disabled) {
            background: $black800;
        }
        &.--disabled {
            opacity: 0.6;
            cursor: default;
        }
    }
}

.pdf-loader-container {
    height: 100%;
    position: absolute;
    z-index: 999;
}

.textLayer {
    opacity: 0.1;
    ::selection {
        background: blue;
    }
}

.pdf-viewer-iframe {
    &__banner {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    &__btn {
        display: flex;
        align-items: center;
        gap: 2px;
        color: $Primary;
        cursor: pointer;
        &:hover {
            color: $PrimaryDarker;
        }
    }

    &__icon {
        font-size: 20px;
        transform: rotate(90deg);
    }
}
