.dateFilter {
    &__container {
        cursor: pointer;
        position: relative;
        padding: 0 8px 0 12px;
        min-height: 28px;
        color: $black800;
        border-radius: 6px;
        border: 1px solid $grey300;
        transition: all 0.3s;
        &.selected {
            background-color: $primary10;
            border-color: #bdd2ff;
            color: $Primary;
        }
        &.disabled {
            color: $grey400;
            cursor: not-allowed;
        }
        &.open {
            color: $Primary;
        }
        &:hover:not(.disabled) {
            background-color: $primary10;
            border-color: #bdd2ff;
            color: $Primary;
        }
    }
    &__input {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }
    }
    &__chevron {
        width: 16px;
        height: 16px;
        transition: transform 0.3s;
        &.open {
            transform: rotateX(180deg);
        }
    }
}
