@import '../../styles/Variables';

.sn-input {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 28px;
    transition: border-color 250ms ease-in;

    &:hover:not(.--disabled) {
        cursor: pointer;
    }

    &.--bold {
        font-weight: bold;
    }

    &.--processing {
        cursor: wait;
    }

    &__label{
        margin-right: 5px;
    }

    &__chevron-icon {
        width: 16px;
        transition: transform 0.3s;
        &.--flipped {
            transform: rotateX(180deg);
        }
    }

    &__menu{
        display: flex;
        flex-direction: column;
        gap: 13px;
        padding: 24px;
        background-color: $grey50;
        
        &__row{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }

        &__progress-overlay{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.25);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
        }

        &__progress{
            color: $Aquant_new_brand_color !important;
        }
    }

    &__search{
        margin-right: 8px;
        width: 341px;
    }

    &__message{
        font-size: 12px;
        color: $grey500;
    }

    &__error_message {
        font-size: 12px;
        color: $red500;
    }

    &__error_icon {
        height: 16px;
        width: 16px;
        color: $red500;
    }
}
