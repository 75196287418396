.edit-response-modal {
    &__side-panel {
        z-index: 1209;
    }

    &__header {
        display: flex;
        gap: 13px;
        align-items: center;
        &__status {
            margin-top: 5px
        }
    }
    &__body {
        @extend .scrollbar;
        flex-direction: column;
        display: flex;
        padding: 30px 40px;
        gap: 24px;
        height: 100%;
        overflow-y: auto;
        scrollbar-gutter: stable;
    }
    &__metadata {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &__item{
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: max-content;
        }
    }
    &__link {
        color: $Primary;
        cursor: pointer;
    }
    &__text-area {
        padding: 40px 32px 0px 35px;
        border-radius: 0px 16px 0px 0px;
    }
    &__response {
        gap: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        &__box {
            padding: 25px;
            border-radius: 0px 16px 16px 16px;
            border: 1px solid $grey300;
            &.edit-mode {
                border: none;
                padding: 0;
            }
        }
        &__disclaimer {
            border-radius: 0px 16px 0 0;
            border-bottom: 1px solid #66D9FE;
            background-color: #E8F9FF;
            display: flex;
            gap: 6px;
            padding: 18px 16px 18px 16px;
            color: $darkBlue3;
            margin: -25px -25px 13px -25px;
            &__icon {
                min-width: 24px;
                margin-top: -2px;
            }
        }
        &__header {
            margin-bottom: 16px;
            &__date {
                color: $grey500;
            }
            &__expert {
                border-radius: 48px;
                background: linear-gradient(90deg, #c9f5ff 0%, #adddff 100%);
                gap: 4px;
                padding: 4px 16px 4px 8px;
                color: $black800;
            }
        }
        &__body {
            white-space: pre-wrap;
            word-break: break-word;
        }
        &__footer {
            margin-top: 22px;
            &__toggle {
                cursor: pointer;
                gap: 13px;
                transition: all 0.3s;
                color: $grey500;
                &:hover {
                    color: $Primary;
                }
            }
            &__edit {
                margin-left: auto;
                color: $grey500;
                cursor: pointer;
                transition: color 0.3s;
                &:hover {
                    color: $Primary;
                }
            }
        }
    }

    &__loader{
        background-color: rgba(248, 249, 252, 0.6);
        height: 100%;
    }

}