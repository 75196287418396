$threadsPanelWidth: 340px;
.threadsBody {
    &__loading {
        border-radius: 0 8px 0 0;
        padding: 32px 45px 10px 45px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-gutter: stable;
        gap: 15px;
    }
}
.threadsPanel {
    &__container {
        width: 0;
        transform: translateX(-$threadsPanelWidth);
        background: $grey800;
        border-radius: 0px 8px 0px 0px;
        margin-top: 72px;
        &.hide-panel {
            animation: hidePanel 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
        &.show-panel {
            animation: showPanel 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
    }
    &__upper-part {
        height: calc(100% - 64px);
    }
    &__header {
        margin: 24px 20px 35px 30px;
        color: $grey100;
        &.withSearchInput {
            margin-bottom: 15px;
        }
        &__icons {
            gap: 8px;
            color: $grey400;
        }
        &__sort,
        &__search {
            cursor: pointer;
            &:hover {
                color: white;
            }
            &.selected {
                color: white;
            }
        }
        &__searchInput {
            margin-left: 30px;
            margin-bottom: 25px;
            padding-right: 28px;
        }
    }
    &__body {
        margin-left: 15px;
        gap: 25px;
        height: calc(100% - 85px);
        overflow-x: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: $scrollBarWidth;
        }
        &::-webkit-scrollbar-thumb {
            background: $grey600;
            border-radius: 24px;
        }
    }
    &__footer {
        cursor: pointer;
        background: $grey900;
        height: 64px;
        & > svg {
            margin-left: 30px;
        }
        &.disabled {
            cursor: not-allowed;
        }
    }
    &__custom-footer {
        justify-content: center;
        display: flex;
        text-align: center;
        gap: 8px;
        cursor: pointer;
        background: $grey900;
        height: 64px;
        & > svg {
            margin-left: 30px;
        }
        &.disabled {
            cursor: not-allowed;
        }
    }
}

.footer-logo {
    display: flex;
    align-items: center;
    height: 100%; 
    margin-top: 5px; 
    margin-left: 30px;
}
.aquant-logo {
    display: flex;
    align-items: center;
    height: 100%; 
    margin-top: 5px; 
}


.by-text {
    color: white; 
    margin-top: 10px;
    font-family: Noto Sans;
    font-size: 11.97px;
    font-weight: 100;
    line-height: 30.2px;
    letter-spacing: 0.09438220411539078px;
    text-align: center;

    }

.singleModelThread {
    &__parent-container {
        margin-bottom: 16px;
    }
    &__parent {
        width: fit-content;
        cursor: pointer;
        padding-bottom: 10px;
        margin-left: 15px;
        & > svg {
            height: 17px;
            min-width: 24px;
            margin-top: -1px;
            margin-right: 3px;
            color: $grey200;
            transition: transform ease 0.3s;
        }
        &.open {
            & > svg {
                transition: transform ease 0.3s;
                transform: rotate(90deg);
            }
        }
    }
    &__main-header {
        color: $grey200;
    }
    &__sub-header {
        color: $grey400;
        margin-top: 3px;
    }
    &__children-container {
        margin-left: 25px;
    }
    &__show-more {
        cursor: pointer;
        color: #a7c3ff;
        padding: 16px 0 16px 65px;
        gap: 10px;
        & > svg {
            width: 16px;
        }
        &:hover {
            color: white;
        }
    }
}

.dateThread {
    &__date-header {
        margin: 32px 0 10px 15px;
        color: $grey400;
        &:nth-of-type(1) {
            margin-top: 0;
        }
    }
    &__show-more-btn {
        cursor: pointer;
        color: #a7c3ff;
        padding: 16px 0 16px 40px;
    }
    &__loading {
        padding: 16px 0 17px 40px;
        background-color: transparent;
    }
}

.innerThread {
    &__wrapper {
        position: relative;
        transition: background-color ease-in 0.3s;
        border-radius: 2px 0px 0px 2px;
        &:hover:not(.deleted) {
            background: $grey900;
            cursor: pointer;
            > .innerThread__container {
                border-bottom: 1px solid transparent;
            }
        }
        &.selected {
            background: $grey900;
            > .innerThread__container {
                border-bottom: 1px solid transparent;
            }
        }
    }
    &__deleted {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $grey800;
        z-index: 1;
        opacity: 0.5;
    }
    &__selected {
        opacity: 1;
        position: absolute;
        left: 0;
        background: linear-gradient(#2384ff, #1ecbff);
        border-radius: 2px 0px 0px 2px;
        width: 4px;
        height: 100%;
        transition: opacity ease-in 0.3s;
        &.hide {
            opacity: 0;
        }
    }
    &__container {
        position: relative;
        min-width: -webkit-fill-available;
        gap: 2px;
        margin-right: 20px;
        margin-left: 15px;
        border-bottom: 1px solid $grey700;
        padding: 20px 0;
        transition: border-bottom ease-in 0.3s;
        & > svg {
            margin-top: -5px;
            color: $grey200;
        }
        &:hover {
            .innerThread__investigation {
                padding-right: 8px;
                padding-left: 2px;
                -webkit-animation: fadeInBackground 0.5s;
                animation: fadeInBackground 0.5s;
            }
            .innerThread__investigation-text {
                display: block;
                -webkit-animation: fadeIn 1s;
                animation: fadeIn 1s;
            }
            .innerThread__trash {
                visibility: visible;
            }
        }
        &.no-border {
            border: none;
        }
    }
    &__data {
        gap: 8px;
    }
    &__header {
        color: $grey200;
        max-width: 230px;
    }
    &__date,
    &__model {
        color: $grey400;
    }
    &__date-investigation {
        gap: 6px;
    }
    &__investigation {
        background: $grey700;
        cursor: pointer;
        color: $grey200;
        border-radius: 48px;
        gap: 3px;
        border-radius: 48px;
        &:hover {
            background: black;
        }
        > svg {
            min-width: 16px;
        }
    }
    &__investigation-text {
        display: none;
    }
    &__trash {
        position: absolute;
        bottom: 15px;
        right: 0;
        color: $grey400;
        visibility: hidden;
        &:hover {
            color: #ff9292;
        }
    }
}

.threadsSortPopover {
    &__container {
        width: 200px;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 2px 10px 0px rgba(49, 63, 82, 0.21);
        padding: 12px 0;
    }
    &__option {
        cursor: pointer;
        padding: 4px 0 4px 20px;
        &:hover {
            background-image: linear-gradient(
                90deg,
                #e3eefe 0.58%,
                #fefeff 110.31%
            );
        }
        &.selected {
            background-image: linear-gradient(
                90deg,
                #e3eefe 0.58%,
                #fefeff 110.31%
            );
        }
    }
    &__empty-icon {
        width: 24px;
        height: 24px;
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes fadeInBackground {
    from {
        width: 16px;
    }
    to {
        width: 98px;
    }
}
@keyframes fadeInBackground {
    from {
        width: 16px;
    }
    to {
        width: 98px;
    }
}
@keyframes hidePanel {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        min-width: $threadsPanelWidth;
    }
    100% {
        -webkit-transform: translateX(-$threadsPanelWidth);
        transform: translateX(-$threadsPanelWidth);
        min-width: 0;
    }
}

@keyframes showPanel {
    0% {
        -webkit-transform: translateX(-$threadsPanelWidth);
        transform: translateX(-$threadsPanelWidth);
        min-width: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        min-width: $threadsPanelWidth;
    }
}
