@import '../../styles/Variables.scss';
.date-range-picker{
    &__shortcuts{
        display: flex;
        gap: 8px;
        padding: 22px 19px 8px 19px;
        flex-wrap: wrap;
        max-width: 330px;
        &__item {
            padding: 6px 12px;
            border-radius: 16px;
            cursor: pointer;
            transition: all 0.3s;
            color: $black800;
            background-color: $grey100;
            &:hover {
                background-color: $grey200;
            }
        }
    }
}

:export {
    primaryColor: $Primary;
    fontFamily: $fontFamily;
}