.popoverBox {
    &__root {
        box-shadow: 0px 2px 10px rgba(49, 63, 82, 0.211593) !important;
        border-radius: 6px !important;
        margin-top: 10px !important ;
    }
    &__container {
        padding-top: 8px;
        min-width: 177px;
    }
    &__rows {
        max-height: 256px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    &__row {
        max-width: 400px;
        padding: 6px 20px;
        color: $black800;
        cursor: pointer;
        transition: background-color 0.5s ease;
        &:hover:not(.--processing){
            background: $grey200;
        }
        &:last-child {
            padding-bottom: 15px;
        }
        &.--disabled {
            pointer-events: none;
            opacity: $opacityDisabled;
        }
        &.--processing {
            cursor: progress;
            opacity: $opacityDisabled;
        }
    }
    &__icon {
        height: 24px;
    }
}
