// Hides popper tooltip when it goes out of boundaries.
[x-out-of-boundaries] {
    display: none;
}

.feedback-message-tooltip {
    background: $grey700 !important;
    font-family: NotoSans, sans-serif !important;
    font-size: 11px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    white-space: nowrap;
    max-width: 200px;
    box-shadow: 0px 2px 10px 0px rgba(49, 63, 82, 0.21);
    &__arrow {
        color: $grey700 !important;
    }
}


.feedback-message-btns {
    display: flex;
    align-items: center;
    gap: 10px;
    &__btn {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $grey100;
        transition: opacity 300ms;
        color: $grey500;
        cursor: pointer;
        &:hover {
            color: $Primary;
            background: $grey200;
        }
        &.--progressing {
            transition: opacity 300ms;
            opacity: 0.7;
            cursor: progress;
            &:hover {
                color: $grey500;
            }
        }
        &.--active {
            color: $Primary;
        }
    }
}

.rewrite-feedback {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey100;
    border-radius: 0px 0px 16px 16px;
    padding: 14px 32px 15px;
    &.--small {
        padding: 16px 24px;
        flex-direction: column;
        gap: 12px;

        .rewrite-feedback__label {
            align-self: start;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__button {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 16px 0 4px;
        height: 32px;
        color: $grey500;
        border-radius: 50px;
        border: 1px solid $grey200;
        transition: opacity 300ms;
        background-color: $grey50;
        cursor: pointer;
        &__text {
            width: max-content;
        }
        &.--small {
            gap: 4px;
            padding: 0 12px 0 4px;
        }
        &:hover {
            color: $Primary;
            background: #fff;
        }
        &.--progressing {
            opacity: 0.7;
            cursor: progress;
        }
        &.--active {
            color: #fff;
            background: $Primary;
            &:hover {
                background: $PrimaryDarker;
            }
        }
    }

    &__icon {
        background-color: $grey100;
        border-radius: 100%;
        min-width: 24px;
        min-height: 24px;

        &.--active {
            color: $Primary;
        }
    }
}
