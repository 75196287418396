@import '~aqui/alf.palette';

$centerMargin: 50%;

.floating-menu {
    position: absolute;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    color: $grey200;
    background-color: $textDefault;
    height: 42px;
    min-width: 157px;
    bottom: 25px;
    left: $centerMargin;
    transform: translate(-$centerMargin, 0);
    margin-right: -$centerMargin;
    box-shadow: 0px 8px 28px 0px #0d246166;

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 10px 8px 8px;
        &:not(:last-child) {
            border-right: 1px solid $grey800;
        }

        &.--clickable {
            cursor: pointer;
            &:hover {
                &:last-child {
                    border-radius: 0 25px 25px 0;
                }
                &:first-child {
                    border-radius: 25px 0 0 25px;
                }

                background-color: $grey800;
            }
        }
        &.--disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }
    &__add-persona {
        color: $grey200;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        &:hover {
            background-color: $grey800;
        }
    }
}
