.kaSection {
    &__container {
        @extend .scrollbar;
        flex: 1;
        border-radius: 0 8px 0 0;
        background: $grey50;
        padding: 32px 45px 10px calc(53px - $scrollBarWidth);
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-gutter: stable;
        .withSkeleton {
            margin-top: 24px;
        }
    }
    &__header {
        color: $black800;
        font-weight: 400;
        margin-left: 4px;
        margin-bottom: 15px;
    }
    &__answer-sources-header {
        padding-bottom: 8px;
    }
}

.ka-list {
    &__knowledge-articles {
        margin-top: 8px;
        gap: 8px;
        &.--in-portal {
            margin-top: 15px;
            gap: 16px;
        }
    }

    &__ka-btns_conatiner {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &.--in-portal {
            margin-bottom: 16px;
        }
    }

    &__ka-btn {
        height: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 4px;
        &__text,
        &__icon {
            color: $grey500;
            transition: rotate 0.3s;
            &--open {
                rotate: 180deg;
            }
        }

        &:hover {
            cursor: pointer;
            .ka-list__ka-btn__text,
            .ka-list__ka-btn__icon {
                color: $Primary;
            }
        }
    }

    &__show-more-btn {
        height: 32px;
        padding: 8px 0px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: $grey100;
        flex: 1;
        margin-left: 0;

        &.--with-show-less {
            margin-right: 18px;
        }
    }
    &__show-less-btn {
        height: 32px;
        padding: 8px 0px;
        align-items: center;
        margin-left: 0;
    }
    &__separator {
        border-top: 1px solid $grey200;
        margin-top: 10px;
        margin-bottom: 5px;
        width: '100%';
    }
}

.ka-row {
    @extend .show-from-left;
    &__hover-area {
        max-width: max-content;
        gap: 4px;
        transition: background-color 0.3s ease;
        border-radius: 40px;
        padding: 0 4px;
        color: $Primary;
        cursor: pointer;
        height: 24px;
        &:hover {
            background: #fff;
            color: $Primary;
            .ka-row__page-num{
                background-color: #fff;
            }
        }
        &.--multiple-pages {
            padding-right: 0;
            border-radius: 5px 10px 10px 5px;
        }
        &--active{
            background: rgba(232, 0, 255, 0.10);;
            color: $black800;
        }
        &.--disabled {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
    &__type-icon {
        min-width: 16px;
    }

    &__file-name{
        flex: 1;
        &.--in-portal {
            font-size: 14px !important;
        }
    }

    &__num-of-results-indicator {
        height: 20px;
        display: flex;
        padding: 0 4px 0 12px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: $grey100;
    }

    &__chevron-icon {
        transition: rotate 0.3s;
        &--open {
            rotate: 180deg;
        }
    }

    &__pages-list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-left: 24px;
        margin-top: 6px;
    }

    &__page {
        padding-left: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__page-num{
        color: $grey500;
        display: flex;
        min-width: 40px;
        padding: 0px 4px;
        justify-content: center;
        align-items: center;
        height: 20px;
        border-radius: 14px;
        background: $grey100;
        transition: background-color 0.3s ease;
        white-space: nowrap;
        cursor: pointer;
        &.--multiple-pages {
            &:hover {
                background-color: #fff;
                color: $grey500;
            }
        }
        &.--tooltip-num {
            background: $black800;
            color: $White;
        }
        &--active{
            background: rgba(232, 0, 255, 0.10);;
            color: $black800;

        }
        &.--disabled {
            opacity: 0.4;
            cursor: not-allowed;
            &:hover {
                color: $Primary;
                background: $grey100;
            }
        }
    }

    &__page-bullet {
        border-radius: 100%;
        background: $grey300;
        width: 5px;
        height: 5px;
    }
}

.-ka-hover-tooltip-area {
    max-width: max-content;
    border-radius: 6px;
    padding: 14px 12px;
    color: $White;
    cursor: pointer;
}

.ka-tooltip-filename {
    flex: 1;
    padding: 0px 4px;
}