.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.align-baseline {
    align-items: baseline;
}

.justify-center {
    justify-content: center;
}

.justify-flex-end {
    justify-content: flex-end;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.align-flex-start {
    align-items: flex-start;
}

.justify-flex-start {
    justify-content: flex-start;
}

.flex-wrap {
    flex-wrap: wrap;
}

.align-flex-end {
    align-items: flex-end;
}

.full-height {
    height: 100%;
}
.no-height {
    height: 0;
}

.full-width {
    width: 100%;
}

.hidden {
    display: none;
}
.visible-hidden {
    visibility: hidden;
}

.inline {
    display: inline;
}

.header {
    font-size: 2em;
}

.no-border {
    border: none !important;
}

.clickable {
    cursor: pointer;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-hidden {
    overflow-x: hidden;
    overflow-y: hidden;
}

.absolute {
    position: absolute;
}

.lines-limit-1 {
    text-overflow: ellipsis;
    overflow-y: hidden;
    overflow-x: hidden;
    white-space: nowrap;
}

.lines-limit-2 {
    margin: 0;
    padding: 0;
    line-height: 18px;
    overflow-y: hidden;
    overflow-x: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.lines-limit-3 {
    overflow-y: hidden;
    overflow-x: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.bold {
    font-weight: 700 !important;
}

.rtl {
    direction: rtl;
}
.scrollbar {
    &::-webkit-scrollbar {
        width: $scrollBarWidth;
        height: $scrollBarWidth;
    }

    &::-webkit-scrollbar-thumb {
        background: $grey300;
        border-radius: 60px;
    }
}

.tooltip {
    background: $grey700 !important;
    font-family: NotoSans, sans-serif !important;
    padding: 8px 16px !important;
    font-size: 11px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    box-shadow: 0px 2px 10px 0px rgba(49, 63, 82, 0.21);
    &__smallChatHeader-icon {
        margin-top: -15px !important;
        &.minimized-header {
            margin-top: -8px !important;
        }
    }
    &__copy-to-clipboard {
        margin-bottom: -15px !important;
    }
    &__edit-suggestion-question {
        border-radius: 6px;
        box-shadow: 0px 2px 10px 0px rgba(49, 63, 82, 0.21);
    }
    &__select-button-icon {
        margin-bottom: 5px !important;
    }
    &__sideModal-arrows {
        margin-top: -3px !important;
    }
    &__arrow {
        color: $grey700 !important;
    }
    &__selected-item {
        margin-bottom: 30px !important;
    }
    &__threadsPanel {
        margin-bottom: 4px !important;
    }
    &__close-top {
        margin-bottom: 4px !important;
    }
}
