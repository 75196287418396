.addPersonaModal {
    &__body-container {
        background: $grey50;
        padding-bottom: 15px;
        border-bottom: 1px solid $grey100;
    }
    &__body {
        gap: 15px;
    }
    &__add-name {
        gap: 5px;
    }
    &__add-description {
        gap: 5px;
    }
    &__persona-sources-search {
        width: 150px;
        margin-left: 8px;
    }
    &__persona-sources-popover {
        z-index: 999999;
    }
    &__persona-sources-menu {
        max-width: 300px !important;
        &.height-limit {
            & > div {
                max-height: 150px;
            }
        }
    }
}

.addUsersBox {
    &__container {
        gap: 5px;
    }
    &__subtext {
        color: $grey500;
    }
    &__textarea > div > div > textarea {
        @extend .scrollbar;
        min-height: 135px;
        max-height: 135px;
    }
}

.addUsersModal {
    &__container {
        gap: 22px;
        height: 100%;
        padding: 33px 41px 0 36px;
    }
    &__persona-select {
        gap: 4px;
    }
    &__textarea > div > div {
        background: white;
    }
    &__textarea > div > div > textarea {
        @extend .scrollbar;
        min-height: 450px;
        max-height: 450px;
    }
    &__personas-popover {
        z-index: 9999;
    }
}

.personaSourcesModalFooter {
    &__container {
        color: $Primary;
        padding: 18px 0 18px 30px;
        cursor: pointer;
    }
    &__icon {
        transform: rotate(45deg);
    }
}

.personaSourcesModalBody {
    &__empty {
        &__container {
            gap: 7px;
            color: $grey500;
        }
        &__copy-sources {
            color: $Primary;
            cursor: pointer;
        }
    }
    &__container {
        padding-top: 20px;
        padding-left: 35px;
    }
    &__sources-count {
        color: $grey500;
        margin-bottom: 26px;
    }
    &__sources {
        gap: 12px;
        overflow-y: auto;
        height: calc(100% - 42px);
        padding-bottom: 20px;
        padding-right: 30px;
    }
    &__source-icon {
        gap: 4px;
    }
    &__source-model {
        color: $grey500;
        margin-left: 20px;
    }
    &__icon {
        min-width: 16px;
    }
}

.defaultCell {
    border-radius: 12px;
    background: $primary10;
    color: $Primary;
    padding: 1px 8px;
    width: fit-content !important;
}

.personaLoadingOverlay {
    &__container {
        height: 100%;
        background-color: rgba(255, 255, 255, 0.38);
        gap: 18px;
    }
    &__text {
        padding: 18px 16px;
        border-radius: 8px;
        border: 1px solid $grey100;
        background: #fff;
        width: 350px;
        text-align: center;
        white-space: break-spaces;
    }
}
